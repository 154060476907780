<template>
  <div>
    <v-date-picker
      class="rounded-0"
      v-model="date"
      full-width
      header-color="orange lighten-1"
      color="orange lighten-1"
      no-title
      :first-day-of-week="1"
    ></v-date-picker>
    <job-list v-if="userPermissions.includes(13)"></job-list>
  </div>
</template>

<script>
import JobList from '@/components/Jobs/JobList.vue';

export default {
  name: 'Navigation',
  computed: {
    date: {
      get() {
        return this.$store.state.date;
      },
      set(value) {
        this.$store.commit('setDate', value);
      },
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  components: {
    JobList,
  },
};
</script>
